import React from "react";
import "./Home.css";

import StockDrawdown from "../components/StockDrawdown.js"

const Home = () => {
  return (
    <div className="index-drawdowns">
      <div className="page-header">
        <h1 className="h-1">Index Drawdowns</h1>
      </div>
      <div className="stock-drawdown-container">
        <StockDrawdown>Text for StockDrawdown 1</StockDrawdown>
        <StockDrawdown>Text for StockDrawdown 2</StockDrawdown>
        <StockDrawdown>Text for StockDrawdown 3</StockDrawdown>
      </div>
    </div>
  );
};

export default Home;
