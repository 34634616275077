import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="frame">
      <div className="join_investors">
      <p>
      This website is for informational purposes only and should not be relied upon for financial advice.
      </p>
      </div>
      <div className="join_investors">
        <p>
          <a href="/privacy-policy">Privacy policy</a> | &copy; 2024 by <a href="https://www.linkedin.com/in/aris-vinsant-960037138/">Aris Vinsant</a> 
        </p>
      </div>
    </div>
  );
};

export default Footer;