import React from 'react';
import './Navbar.css'

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="container">
                 <ul className="hidden-ul">
                    <li><a className="nav-link home" href="/">Home</a></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;